<template>
  <div class="">
    <div class="row my-4">
      <!-- First Column -->

      <div class="col-md-6 col-lg-6 col-sm-12">
        <v-container v-if="progress" type="image" class="loding">
          <v-skeleton-loader type="image"></v-skeleton-loader>
          <v-skeleton-loader type="image"></v-skeleton-loader>
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-container>
        <form v-else>
          <div class="background-white mt-3">
            <div class="title">
              <div class="position">{{ $t("REGULATION") }}</div>
            </div>
          </div>
          <div class="center borderPointier mt-3 p-3">
            <b-form-group :label="$t('Debut travaux')" class="mt-2">
              <b-form-input
                type="date"
                v-model="getdevisMonoFiche.data.devis.debut_travaux"
                required
                class="form-input custom-placeholder custom-input"
                @input="
                  updateDevis(
                    'debut_travaux',
                    getdevisMonoFiche.data.devis.debut_travaux
                  )
                "
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('Fin travaux')">
              <b-form-input
                type="date"
                v-model="getdevisMonoFiche.data.devis.fin_travaux"
                required
                @input="
                  updateDevis(
                    'fin_travaux',
                    getdevisMonoFiche.data.devis.fin_travaux
                  )
                "
                class="form-input custom-placeholder custom-input"
              ></b-form-input>
            </b-form-group>
            <div class="mb-3">
              <div class="fieldset mt-3">
                <div class="legend mb-2">{{ $t("MOD_PAYMENT") }}</div>

                <multiselect
                  v-model="selectedMoyensPaiement"
                  :searchable="false"
                  :close-on-select="true"
                  :options="optionspaiement"
                  :multiple="true"
                  :placeholder="$t('sélectionnez_moy')"
                  label="text"
                  track-by="value"
                  required
                  class="form-input custom-placeholder custom-input custom-margin-bottom"
                ></multiselect>
              </div>
            </div>
          </div>
        </form>
      </div>

      <!-- Second Column -->
      <div class="col-md-6 col-lg-6 col-sm-12">
        <v-container v-if="progress" type="image" class="loding">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-container>

        <form v-else>
          <div class="background-white mt-3">
            <div class="title">
              <div class="position">
                {{ $t("AGENT") }}/{{ $t("DELEGATAIRE") }}
              </div>
            </div>
          </div>
          <div class="borderPointier mt-3 pt-3 px-3">
            <div class="d-flex justify-content-between">
              <div class="">{{ $t("AGENT") }}/{{ $t("DELEGATAIRE") }}</div>
              <button id="add-with-popover"
                class="mb-1 py-0 newstore rounded"
                @click.prevent="openModal('add')"
              >
               +
              </button>
              <b-popover
      target="add-with-popover"
      placement="top"
      triggers="hover focus"
    >
    {{ $t("Vous pouvez ajouter un nouveau Mandataire/Délégataire") }}
    </b-popover>
              <mandataireEtpartenairemodale
                v-if="isModalOpen"
                :isOpen="isModalOpen"
                @close="closeModal"
                @confirm="handleConfirm"
                :updateData="update_EntiteExterne"
              ></mandataireEtpartenairemodale>
            </div>
            <b-form-group>
              <multiselect
                v-model="selectedMondataire"
                :searchable="true"
                :close-on-select="true"
                :multiple="false"
                :options="getMondataireEntitesExternes"
                label="nom"
                track-by="id"
                :placeholder="$t('Select_agent') + '/' + $t('DELEGATAIRE')"
                class="form-input custom-placeholder custom-input"
              />
              <!-- <template #beforeList>
    <div class="newstore" @click="handleBeforeListClick">
      {{ $t("NEW") }} {{$t("AGENT") }}/{{ $t("DELEGATAIRE") }}
    </div>
  </template></multiselect> -->
            </b-form-group>
          </div>
          <v-container v-if="progress" type="image" class="loding">
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </v-container>
          <div v-else class="borderPointier mt-3 pt-3 px-3">
            <div class="background-white">
              <div class="title">
                <div class="position">{{ $t("oblige") }}</div>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div class="">{{ $t("oblige") }}</div>
              <button id="addoblige-with-popover"
                class="mb-1 py-0 newstore rounded"
                @click.prevent="openModal('add')"
              >
                +
              </button>
              <b-popover
      target="addoblige-with-popover"
      title="Info"
      placement="top"
      triggers="hover focus"
    >
    Vous pouvez ajouter un nouveau oblige
    </b-popover>
            </div>
            <b-form-group>
              <multiselect
                v-model="selectedPartenaire"
                :searchable="true"
                :close-on-select="true"
                :multiple="false"
                :options="getPartenaireEntitesExternes"
                label="nom"
                track-by="id"
                :placeholder="$t('Select_oblige')"
                class="form-input custom-placeholder custom-input"
              />
            </b-form-group>
          </div>
        </form>
      </div>
    </div>
    <!--button-->
    <div class="d-flex justify-content-end mt-5">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
        {{ $t("NEXT") }}
        <div class="loading ml-2" v-if="isLoading">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div>
    <Snackbar ref="snackbar" class="mt-5" />
  </div>
</template>
<script>
import moment from "moment";
import { mapActions, mapGetters, mapState } from "vuex";
import domain from "@/environment";
import axios from "axios";
import mandataireEtpartenairemodale from "@/components/Ajoutmodel/mandataireEtpartenairemodale.vue";
import Snackbar from "@/components/ui/errorSnackbar.vue";
export default {
  components: {
    Snackbar,
    mandataireEtpartenairemodale,
  },
  props: {
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      update_EntiteExterne: null,
      paiement_state: [],
      isLoading: false,
      selectedMoyensPaiement: [],
      partenaire: false,
      mondataire: false,
      isModalOpen: false,
      optionspaiement: [
        {
          value: "Organisme_de_financement",
          text: this.$t("Organisme_de_financement"),
        },
        {
          value: "cheque_virement_espece",
          text: this.$t("Cheque_virement_espece"),
        },
        {
          value: "Certificat_Economie_energie",
          text: this.$t("Certificat_Economie_energie"),
        },
        {
          value: "Ma_prime_renove",
          text: this.$t("Ma_prime_renove"),
        },
        {
          value: "Autre",
          text: this.$t("Autre"),
        },
      ],
      optionsTypeClient: [
        { value: "type.particulier", text: this.$t("PARTICULAR") },
        { value: "type.professionnel", text: this.$t("PROFESSIONAL") },
      ],
    };
  },
  computed: {
    ...mapState({
      token: (state) => state.token,
    }),
    ...mapGetters([
      "getdevisMonoFiche",
      "getMondataireEntitesExternes",
      "getPartenaireEntitesExternes",
      "getOnlineUser",
    ]),
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    isCommercialBatigo() {
      return this.getOnlineUser.role == "user.commercial-batigo";
    },
    isAssistant() {
      return this.getOnlineUser.role == "user.assistant";
    },
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    devisData() {
      return this.getdevisMonoFiche?.data?.devis || {};
    },
    selectedPartenaire: {
      get() {
        if (typeof this.partenaire === "object" && this.partenaire !== null) {
          return this.partenaire;
        } else {
          return this.getPartenaireEntitesExternes.find(
            (option) => option.id === this.partenaire
          );
        }
      },
      set(value) {
     
        this.partenaire = value ? value.id : null;
        this.getdevisMonoFiche.data.devis.partenaire_id = value
          ? value.id
          : null;
 
        this.getdevisMonoFiche.data.devis.partenaire = {
          id: value.id,
          nom: value.nom,
          siret: value.siret,
        };
        this.setDevisMonoFiche(this.getdevisMonoFiche);
      },
    },
    selectedMondataire: {
      get() {
        if (typeof this.mondataire === "object" && this.mondataire !== null) {
          return this.mondataire;
        } else {
          return this.getMondataireEntitesExternes.find(
            (option) => option.id === this.mondataire
          );
        }
      },
      set(value) {
        if (value && value.id) {
          this.mandataire = value.id;
          this.getdevisMonoFiche.data.devis.mandataire_id = value.id;
          this.getdevisMonoFiche.data.devis.mandataire = {
            id: value.id,
            nom: value.nom || "",
            siret: value.siret || "",
          };
        } else {
          this.mandataire = null;
          this.getdevisMonoFiche.data.devis.mandataire_id = null;
          this.getdevisMonoFiche.data.devis.mandataire = null;
        }

        this.setDevisMonoFiche(this.getdevisMonoFiche);
      },
    },
  },

  async created() {
    const devi_ID = this.getdevisMonoFiche.data?.devis?.id;
    
      const data = await this.getPaiement(devi_ID);
      this.paiement_state = data;

  
      const moyensPaiement = Array.from(
        new Set(this.paiement_state.map((item) => item.moyen_paiement))
      );

      this.selectedMoyensPaiement = moyensPaiement
        .map((moyen) => {
          const option = this.optionspaiement.find(
            (option) => option.value === moyen
          );
          return option ? { value: option.value, text: option.text } : null;
        })
        .filter((moyen) => moyen !== null);
   

    if (this.isSuperAdmin) {
      this.allEntitesExternes({
        page: this.page,
        per_page: 100000000000,
        nom: this.nom,
        entreprise_id: this.getdevisMonoFiche.data.devis.entreprise.id,
      });
    } else {
      this.allEntitesExternes({
        page: 1,
        per_page: 1000000000,
        search: "",
        type: "",
        entreprise_id: this.getOnlineUser.entreprise.id,
      });
    }
    if (this.getdevisMonoFiche.data && this.getdevisMonoFiche.data.devis) {
      this.initializeDateData();
      this.formatDates();
    }
  },
  watch: {
    getdevisMonoFiche: {
      handler(newVal) {
        if (newVal?.data?.devis) {
          this.partenaire = newVal.data.devis.partenaire || null;
          this.mondataire = newVal.data.devis.mandataire || null;
          this.formatDates();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions([
      "allEntitesExternes",
      "setDevisMonoFiche",
      "storeEntiteExterne",
    ]),
    async getPaiement(devis_ID) {
      try {
        const response = await axios.get(domain + `/devis_moyens_paiement`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
          params: {
            devis_id: devis_ID,
          },
        });

        return response.data.data;
      } catch (error) {
        throw new Error(
          error.response?.data?.message ||
            "An error occurred while fetching the payment information."
        );
      }
    },

    setup(refresh = false) {
      if (refresh) this.page = 1;

      if (this.getOnlineUser) {
        this.allEntitesExternes({
          page: this.page,
          per_page: 100000000000,
          entreprise_id: this.getdevisMonoFiche.data.devis.entreprise.id,
        });
      }
    },

    async handleConfirm(EntiteExtData) {
      try {
        await this.storeEntiteExterne(EntiteExtData);
        this.closeModal();
        this.setup(true);
      } catch (err) {
        if (this.isObject(err)) {
          for (const [key, value] of Object.entries(err)) {
            if (!this.erreurlist[key]) {
              this.erreurlist[key] = value;
            }
          }
        } else {
          this.errorS = err;
        }
      }
    },
    async postPaiement(payload) {
      const responses = [];
      for (const item of payload) {
        try {
          const dataToSend = {
            devis_id: this.getdevisMonoFiche.data.devis.id,
            moyen_paiement: item,
          };

          const response = await axios.post(
            domain + `/devis_moyens_paiement`,
            dataToSend,
            {
              headers: {
                Authorization: `Bearer ` + this.token,
              },
            }
          );

          responses.push(response);
        } catch (error) {
          throw new Error("Failed to post payment.");
        }
      }
      return responses;
    },
    openModal(action, updateData = null) {
      this.update_EntiteExterne = updateData;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.update_EntiteExterne = null;
    },
    async deletePaiement(ids) {
      const responses = [];

      for (const id of ids) {
        try {
          const response = await axios.delete(
            domain + `/devis_moyens_paiement/${id}`,
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
            }
          );
          responses.push(response.data);
        } catch (error) {
          responses.push({
            id,
            error:
              error.response?.data?.message ||
              "An error occurred while deleting the payment.",
          });
        }
      }

      return responses;
    },
    paiement() {
      if (!this.paiement_state || !Array.isArray(this.paiement_state)) {
        return;
      }

      const selectedValues =
        this.selectedMoyensPaiement?.map((option) => option.value) || [];

      const idsExistants = selectedValues.filter(
        (moyen_paiement) =>
          !this.paiement_state.some(
            (obj) => obj.moyen_paiement === moyen_paiement
          )
      );

      if (idsExistants) {
        this.postPaiement(idsExistants);
      }

      const idsNonExistants = this.paiement_state
        .filter(
          (obj) =>
            !this.selectedMoyensPaiement.some(
              (moyen) => moyen.value === obj.moyen_paiement
            )
        )
        .map((obj) => obj.id);

      if (idsNonExistants) {
        this.deletePaiement(idsNonExistants);
      }
    },
    formatDate(date) {
      return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY");
    },
    goToNextStep() {
      this.isLoading = true;
      this.paiement();
      this.setDevisMonoFiche(this.getdevisMonoFiche);
      const debutTravaux = new Date(
        this.getdevisMonoFiche?.data?.devis?.debut_travaux
      );
      const finTravaux = new Date(
        this.getdevisMonoFiche?.data?.devis?.fin_travaux
      );
      const debutDevis = new Date(
        this.getdevisMonoFiche?.data?.devis?.debut_devis
      );

      if (debutTravaux < debutDevis) {
       
        this.$refs.snackbar.showSnackbar(
          `error_date_devis`
        );
        this.isLoading = false;
        return;
      }
      if (debutTravaux > finTravaux) {
        this.$refs.snackbar.showSnackbar(
          `error_date_travaux`
        );
        this.isLoading = false;
        return;
      }
      if ( ( !(this.getdevisMonoFiche.data.devis.partenaire_id || this.getdevisMonoFiche.data.devis.partenaire)&&
  !(this.getdevisMonoFiche.data.devis.mandataire_id || this.getdevisMonoFiche.data.devis.mandataire) )|| 
  this.selectedMoyensPaiement.length === 0
) {
  this.$refs.snackbar.showSnackbar(`error_reglement`);
  this.isLoading = false;
  return;
}

      // if ( (!this.getdevisMonoFiche.data.devis.mandataire_id&& !this.getdevisMonoFiche.data.devis.partenaire_id) ||this.selectedMoyensPaiement.length==0) {
      //   this.$refs.snackbar.showSnackbar(
      //     `error_reglement`
      //   );
      //   this.isLoading = false;
      //   return;
      // }
      this.isLoading = false;
      this.$emit("changeStep", 8, true);
    },
    goToPrevStep() {
      this.$emit("changeStep", 6);
    },

    initializeDateData() {
      const devis = this.getdevisMonoFiche?.data?.devis;
     
      if (!devis.debut_travaux) {
        devis.debut_travaux = new Date().toISOString().slice(0, 10);
      }

      if (!devis.fin_travaux) {
        devis.fin_travaux = new Date().toISOString().slice(0, 10);
      }
    },

    selectMondataire(mondataire) {
      this.mondataire = { ...mondataire };

      this.getdevisMonoFiche.data.devis.mandataire_id = mondataire.id;
    },
    updateDevis(field, value) {
      this.getdevisMonoFiche.data.devis[field] = value;

      this.setDevisMonoFiche(this.getdevisMonoFiche);
    },
    selectPartenaire(partenaire) {
      this.partenaire = { ...partenaire };
      this.getdevisMonoFiche.data.devis.partenaire_id = partenaire.id;
    },
    formatDates() {
      const formatDateLocal = (dateString) => {
        if (!dateString) return "";
        const date = new Date(dateString);

        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");

        return `${year}-${month}-${day}`;
      };

      if (this.getdevisMonoFiche?.data?.devis?.fin_travaux) {
        this.getdevisMonoFiche.data.devis.fin_travaux = formatDateLocal(
          this.getdevisMonoFiche?.data?.devis?.fin_travaux
        );
      }

      if (this.getdevisMonoFiche?.data?.devis?.debut_travaux) {
        this.getdevisMonoFiche.data.devis.debut_travaux = formatDateLocal(
          this.getdevisMonoFiche?.data?.devis?.debut_travaux
        );
      }
    },
  },
};
</script>
<style scoped>
.custom-margin-bottom {
  margin-bottom: 90px;
}

.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.input-style {
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eaebec;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
}

.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}
.newstore {
  background: #24316d;
  padding: 8px 12px;
  color: #fff;
  cursor: pointer;
  width: 30px;
  height: 30px;
  &:hover {
    background-color: #24316d !important;
  }
}
.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.custom-placeholder::placeholder {
  font-size: 13px;
}
.custom-input {
  box-shadow: none;
}
.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>
