<template>
  <div>
    <div>
      <div>
        <div>
          <div class="row my-4 mx-1 justify-content-center">
            <v-container v-if="progress" type="image" class="loding">
              <v-skeleton-loader type="image"></v-skeleton-loader>
            </v-container>
            <div v-else class="col-lg-8 col-sm-12 col-md-10 borderPointier">
            
              <div class="background-white">
                <div class="title">
                  <div class="position">{{ $t("conditions_technique") }}</div>
                </div>
              </div>
              <form @submit.prevent="renovation_ampleur_indivAdd" class="my-3">
                <div class="fieldset mb-2">
                  <div class="legend mb-2">
                    {{ $t("surface_habitable") }}
                  </div>
                  <b-form-input
                    type="number"
                    @paste="
                      (event) =>
                        onPaste(
                          event,
                          'renovation_ampleur_indivData.surface_habitable'
                        )
                    "
                    @keydown="(event) => onKeyDown(event)"
                    min="0"
                    v-model="renovation_ampleur_indivData.surface_habitable"
                    :placeholder="$t('surface_habitable')"
                    class="form-input custom-placeholder custom-input"
                  >
                  </b-form-input>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="fieldset mb-2">
                      <div class="legend mb-2">
                        {{ $t(" cep_initial") }}
                      </div>
                      <b-form-input
                        type="number"
                        @paste="
                          (event) =>
                            onPaste(
                              event,
                              'renovation_ampleur_indivData.cep_initial'
                            )
                        "
                        @keydown="(event) => onKeyDown(event)"
                        min="0"
                        v-model="renovation_ampleur_indivData.cep_initial"
                        :placeholder="$t('cep_initial')"
                        class="form-input custom-placeholder custom-input"
                      >
                      </b-form-input>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="fieldset mb-2">
                      <div class="legend mb-2">
                        {{ $t("cep_projet") }}
                      </div>
                      <b-form-input
                        type="number"
                        @paste="
                          (event) =>
                            onPaste(
                              event,
                              'renovation_ampleur_indivData.cep_projet '
                            )
                        "
                        @keydown="(event) => onKeyDown(event)"
                        min="0"
                        v-model="renovation_ampleur_indivData.cep_projet"
                        :placeholder="$t('cep_projet ')"
                        class="form-input custom-placeholder custom-input"
                      >
                      </b-form-input>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="fieldset mb-2">
                      <div class="legend mb-2">
                        {{ $t("cef_initial") }}
                      </div>
                      <b-form-input
                        type="number"
                        @paste="
                          (event) =>
                            onPaste(
                              event,
                              'renovation_ampleur_indivData.cef_initial'
                            )
                        "
                        @keydown="(event) => onKeyDown(event)"
                        min="0"
                        v-model="renovation_ampleur_indivData.cef_initial"
                        :placeholder="$t('cef_initial')"
                        class="form-input custom-placeholder custom-input"
                      >
                      </b-form-input>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="fieldset mb-2">
                      <div class="legend mb-2">
                        {{ $t("cef_projet") }}
                      </div>
                      <b-form-input
                        type="number"
                        @paste="
                          (event) =>
                            onPaste(
                              event,
                              'renovation_ampleur_indivData.cef_projet'
                            )
                        "
                        @keydown="(event) => onKeyDown(event)"
                        min="0"
                        v-model="renovation_ampleur_indivData.cef_projet"
                        :placeholder="$t('cef_projet')"
                        class="form-input custom-placeholder custom-input"
                      >
                      </b-form-input>
                    </div>
                  </div>
                </div>
               
                <div class="row">
                  <div class="col-lg-6 col-md-12 col-sm-12">
                    <b-form-group
                      :label="$t('classe_avant_travaux')"
                      label-for="select-type-appareil"
                    >
                      <b-form-select
                        v-model="
                          renovation_ampleur_indivData.classe_avant_travaux
                        "
                        :options="classe_avant_travauxOptions"
                        id="select-type-appareil"
                        class="custom-input"
                      >
                      </b-form-select>
                    </b-form-group>
                   </div>
                  <div class="col-lg-6 col-md-12 col-sm-12">
                    <b-form-group
                      :label="$t('classe_apres_travaux')"
                      label-for="select-type-appareil"
                    >
                      <b-form-select
                        v-model="
                          renovation_ampleur_indivData.classe_apres_travaux
                        "
                        :options="classe_apres_travauxOptions"
                        id="select-type-appareil"
                        class="custom-input"
                      >
                      </b-form-select>
                    </b-form-group>
                    </div>    </div>
          
     <div class="row">
      <div class="col-6">
                    <b-form-group :label="$t('personne_physique')">
              <b-form-select v-model="renovation_ampleur_indivData.personne_physique" :options="personne_physiqueOptions" name="personne_physique" />
            </b-form-group>
                  </div>
      <div class="col-6">
   
          <b-form-group :label="$t('seconde_etape')">
              <b-form-select v-model="renovation_ampleur_indivData.seconde_etape" :options="seconde_etapeOptions" name="seconde_etape" />
            </b-form-group>
        </div>
         
     </div>
     <div class="row">    
      <div class="col-6">
            <b-form-group :label="$t('correspondent_scénarios')">
              <b-form-select v-model="renovation_ampleur_indivData.correspondent_scénarios" :options="correspondent_scénariosOptions" name="correspondent_scénarios" />
            </b-form-group>
           </div>
  <div class="col-6">
    <b-form-group :label="$t('presente_contrainte')">
              <b-form-select v-model="renovation_ampleur_indivData.presente_contrainte" :options="presente_contrainteOptions" name="presente_contrainte" />
            </b-form-group>
  </div>
          </div>
         <div class="row">
          <div class="col-6">
            <b-form-group :label="$t('comprennent_au_moins_deux_postes')">
              <b-form-select v-model="renovation_ampleur_indivData.comprennent_au_moins_deux_postes" :options="comprennent_au_moins_deux_postesOptions" name="comprennent_au_moins_deux_postes" />
            </b-form-group>
          </div>
           <div class="col-6">
            <b-form-group :label="$t('calcul_resistance_conforme')">
              <b-form-select v-model="renovation_ampleur_indivData.calcul_resistance_conforme" :options="calcul_resistance_conformeOptions" name="calcul_resistance_conforme" />
            </b-form-group>
           </div>
         </div>
       <div class="row">
        <div class="col-6">
          <b-form-group :label="$t('systeme_installer')">
              <b-form-select v-model="renovation_ampleur_indivData.systeme_installer" :options="systeme_installerOptions" name="systeme_installer" />
            </b-form-group>
        </div>
            <div class="col-6">
              <b-form-group :label="$t('systeme_concerver')">
              <b-form-select v-model="renovation_ampleur_indivData.systeme_concerver" :options="systeme_concerverOptions" name="systeme_concerver" />
            </b-form-group>
            </div>
       </div>
           <div class="row">
            <div class="col-6">
              <b-form-group :label="$t('emissions_gaz')">
              <b-form-select v-model="renovation_ampleur_indivData.emissions_gaz" :options="emissions_gazOptions" name="emissions_gaz" />
            </b-form-group>
            </div>
          
            
                <div class="col-6">
              <b-form-group :label="$t('basees_sur_audit')">
              <b-form-select v-model="renovation_ampleur_indivData.basees_sur_audit" :options="Options" name="basees_sur_audit" />
            </b-form-group>
            </div>
           </div>
              </form>
     
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!show" class="d-flex justify-content-end mt-4">
                <!-- <b-button v-if="this.visibleTabsLength=1" @click="goToPrevStep"
             class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button> -->
  <b-button
    type="submit"
    @click="goToStep"
    class="button-suivant-style m-3"
  >
    <template v-if="isLoading">
      <div class="loading ml-2">
        <div class="spinner-border spinner-border-sm" role="status"></div>
      </div>
    </template>

    <template v-else>
      {{ $t("CONFIRMER") }}
    </template>
  </b-button>
</div>
    <div v-if="show" class="d-flex justify-content-end mt-4">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
        {{ $t("NEXT") }}
        <div class="loading ml-2" v-if="isLoading">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
      <Snackbar ref="snackbar" class="mt-5" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import domain from "@/environment";
import axios from "axios";
import Snackbar from "@/components/ui/errorSnackbar.vue";
export default {
  components: {
    Snackbar,

  },
  props: {
    visibleTabsLength: {
      type: Number,
      required: true,
    },   
    activeState: {
      type: Number,
    },
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      renovation_ampleur_indivData: null,
      renovation_ampleur_indiv: false,
      type_isolant: null,
      norme: null,
      errorMessage: "",
      activeSteps: [],
      personne_physiqueOptions:  [
        { text: "Oui", value: 1 },
        { text: "Non", value: 0 },
      ],
      seconde_etapeOptions:  [
        { text: "Oui", value: true },
        { text: "Non", value: false },
      ],
      correspondent_scénariosOptions:  [
        { text: "Oui", value: true },
        { text: "Non", value: false },
      ],
      presente_contrainteOptions:  [
        { text: "Oui", value: true },
        { text: "Non", value: false },
      ],
      comprennent_au_moins_deux_postesOptions:  [
        { text: "Oui", value: true },
        { text: "Non", value: false },
      ],
      calcul_resistance_conformeOptions:  [
        { text: "Oui", value: true },
        { text: "Non", value: false },
      ],

      systeme_installerOptions:  [
        { text: "Oui", value: 1 },
        { text: "Non", value: 0 },
      ], 
      systeme_concerverOptions:  [
        { text: "Oui", value: true },
        { text: "Non", value: false },
      ],
      emissions_gazOptions:  [
        { text: "Oui", value: true },
        { text: "Non", value: false },
      ],
      Options:  [
        { text: "Oui", value: true },
        { text: "Non", value: false },
      ],
      classe_apres_travauxOptions: [
          { value: 'A', text: 'A' },
          { value: 'B', text: this.$t("B") },
          { value: 'C', text: this.$t("C") },
          { value: 'D', text: this.$t("D") },
          { value: 'E', text: this.$t("E") },
          { value: 'F', text: this.$t("F") },
          { value: 'G', text: this.$t("G") },
        ],
        classe_avant_travauxOptions: [
          { value: 'A', text: 'A' },
          { value: 'B', text: this.$t("B") },
          { value: 'C', text: this.$t("C") },
          { value: 'D', text: this.$t("D") },
          { value: 'E', text: this.$t("E") },
          { value: 'F', text: this.$t("F") },
          { value: 'G', text: this.$t("G") },
        ],
    };
  },
  created() {
    if (this.getdevisMonoFiche && this.getdevisMonoFiche.data) {
      this.renovation_ampleur_indivData =
        this.initializerenovation_ampleur_indiv();
   
    }
  },
  computed: {
    ...mapState({
      token: (state) => state.token,
    }),
    ...mapGetters(["getdevisMonoFiche"]),
    normeOptions() {
      return this.type_isolant ? this.normesByType[this.type_isolant] : [];
    },
    buttonText() {
      return this.visibleTabsLength <= 1 ? this.$t("NEXT") : this.$t("CONFIRMER");
    },
  },

  watch: {
   
    getdevisMonoFiche: {
      handler(newDevis) {
        if (newDevis && newDevis.data) {
          this.renovation_ampleur_indivData =
            this.initializerenovation_ampleur_indiv();
          this.renovation_ampleur_indiv =
            newDevis.data?.devis.renovation_ampleur_individuelle !== null;
        
        }
      },

      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["setDevisMonoFiche"]),
 
    toggleStep(step) {
      const index = this.activeSteps.indexOf(step);
      if (index === -1) {
        this.activeSteps.push(step);
      } else {
        this.activeSteps.splice(index, 1);
      }
    },
    isDisabled(step) {
      return this.activeSteps.includes(step);
    },
    async postIsolationMur(payload) {
      try {
        const response = await axios.post(
          domain + `/renovation_ampleur`,
          payload,
          {
            headers: {
              Authorization: `Bearer ` + this.token,
            },
          }
        );
        return response.data.data;
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          this.errorMessage = error.response.data.message;
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          this.errorMessage = error.response.data.errors.join(", ");
        } else {
          this.errorMessage = "Vérifiez vos données.";
        }
        this.$refs.snackbar.showSnackbar(this.errorMessage);
        return null;
      }
    },

    async putIsolationMur(payload) {
      try {
        const response = await axios.put(
          domain + `/renovation_ampleur/${payload.id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ` + this.token,
            },
          }
        );
        return response.data.data;
      } catch (error) {
        throw new Error("Failed to post isolation combles toiture.");
      }
    },
    initializerenovation_ampleur_indiv() {
      const renovation_ampleur_indiv =
        this.getdevisMonoFiche?.data?.devis.renovation_ampleur_individuelle ||
        {};
      return {
        id: renovation_ampleur_indiv.id,
        classe_avant_travaux:
          renovation_ampleur_indiv.classe_avant_travaux || "",
        classe_apres_travaux:
          renovation_ampleur_indiv.classe_apres_travaux || "",
          surface_habitable: renovation_ampleur_indiv.surface_habitable || "",
        cef_initial: renovation_ampleur_indiv.cef_initial || "",
        cep_initial: renovation_ampleur_indiv.cep_initial || "",
        cep_projet:renovation_ampleur_indiv.cep_projet||"",
        cef_projet: renovation_ampleur_indiv.cef_projet || "",
        personne_physique: renovation_ampleur_indiv.personne_physique || 0,
        seconde_etape: renovation_ampleur_indiv.seconde_etape || false,
        correspondent_scénarios: renovation_ampleur_indiv.correspondent_scénarios || false,
        presente_contrainte: renovation_ampleur_indiv.presente_contrainte || false,
        comprennent_au_moins_deux_postes: renovation_ampleur_indiv.comprennent_au_moins_deux_postes || false,
        calcul_resistance_conforme: renovation_ampleur_indiv.calcul_resistance_conforme || false,
        systeme_installer: renovation_ampleur_indiv.systeme_installer || 0,
        systeme_concerver: renovation_ampleur_indiv.systeme_concerver || false,
        emissions_gaz: renovation_ampleur_indiv.emissions_gaz || false,
        basees_sur_audit: renovation_ampleur_indiv.basees_sur_audit || false,
      };
    },
    async isolationAdd() {
      const response = await this.postIsolationMur(
        this.renovation_ampleur_indivData
      );
      if (response.id !== null) {
        this.getdevisMonoFiche.data.devis.renovation_ampleur_individuelle_id =
          response.id;
        this.getdevisMonoFiche.data.devis.renovation_ampleur_individuelle = {
          id: this.getdevisMonoFiche.data.devis
            .renovation_ampleur_individuelle_id,
          classe_apres_travaux:
            this.renovation_ampleur_indivData.classe_apres_travaux,
          classe_avant_travaux:
            this.renovation_ampleur_indivData.classe_avant_travaux,
            basees_sur_audit: this.renovation_ampleur_indivData.basees_sur_audit,
            personne_physique: this.renovation_ampleur_indivData.personne_physique,

            seconde_etape: this.renovation_ampleur_indivData.seconde_etape,

            correspondent_scénarios: this.renovation_ampleur_indivData.correspondent_scénarios,

            presente_contrainte: this.renovation_ampleur_indivData.presente_contrainte,

            comprennent_au_moins_deux_postes: this.renovation_ampleur_indivData.comprennent_au_moins_deux_postes,

            calcul_resistance_conforme: this.renovation_ampleur_indivData.calcul_resistance_conforme,

            systeme_installer: this.renovation_ampleur_indivData.systeme_installer,

            systeme_concerver: this.renovation_ampleur_indivData.systeme_concerver,

            emissions_gaz: this.renovation_ampleur_indivData.emissions_gaz,
            surface_habitable:this.renovation_ampleur_indivData.surface_habitable,
          cef_initial: this.renovation_ampleur_indivData.cef_initial,
          cep_initial: this.renovation_ampleur_indivData.cep_initial,
          cef_projet: this.renovation_ampleur_indivData.cef_projet,
          cep_projet: this.renovation_ampleur_indivData.cep_projet,
        };
        this.setDevisMonoFiche(this.getdevisMonoFiche);
        return response;
      } else {
        this.isLoading = false;

        return;
      }
    },

    async renovation_ampleur_indivAdd() {
      if (this.renovation_ampleur_indiv) {

        const chauffe = {
          ...this.renovation_ampleur_indivData,
          id: this.getdevisMonoFiche.data.devis.renovation_ampleur_individuelle
            .id,
        };
        const reponse = await this.putIsolationMur(chauffe);
        this.getdevisMonoFiche.data.devis.renovation_ampleur_individuelle = {
          id: this.getdevisMonoFiche.data.devis
            .renovation_ampleur_individuelle_id,
          classe_apres_travaux:
            this.renovation_ampleur_indivData.classe_apres_travaux,
          classe_avant_travaux:
            this.renovation_ampleur_indivData.classe_avant_travaux,
            basees_sur_audit: this.renovation_ampleur_indivData.basees_sur_audit,
            personne_physique: this.renovation_ampleur_indivData.personne_physique,

            seconde_etape: this.renovation_ampleur_indivData.seconde_etape,

            correspondent_scénarios: this.renovation_ampleur_indivData.correspondent_scénarios,

            presente_contrainte: this.renovation_ampleur_indivData.presente_contrainte,

            comprennent_au_moins_deux_postes: this.renovation_ampleur_indivData.comprennent_au_moins_deux_postes,

            calcul_resistance_conforme: this.renovation_ampleur_indivData.calcul_resistance_conforme,

            systeme_installer: this.renovation_ampleur_indivData.systeme_installer,

            systeme_concerver: this.renovation_ampleur_indivData.systeme_concerver,

            emissions_gaz: this.renovation_ampleur_indivData.emissions_gaz,
            surface_habitable:this.renovation_ampleur_indivData.surface_habitable,
          cef_initial: this.renovation_ampleur_indivData.cef_initial,
          cep_initial: this.renovation_ampleur_indivData.cep_initial,
          cef_projet: this.renovation_ampleur_indivData.cef_projet,
          cep_projet: this.renovation_ampleur_indivData.cep_projet,
        };

        this.setDevisMonoFiche(this.getdevisMonoFiche);
        return reponse;
      }
      await this.isolationAdd();
    },
    async goToStep() {
      this.isLoading = true;
     await this.renovation_ampleur_indivAdd();
      if (this.errorMessage !== "") {
        this.isLoading = false;
        this.$refs.snackbar.showSnackbar("Tous_obligatoires");
        this.isCompleted = false;

        return;
      }
      this.isLoading = false;
      this.isCompleted = true;
      // if(this.visibleTabsLength<=1){
      //   this.$emit("change",true);
      // }else {
      //   this.$emit("change",false);
      // }
    },
    async goToNextStep() {
      this.isLoading = true;
  
      await this.renovation_ampleur_indivAdd();
      if (this.errorMessage !== "") {
        this.isLoading = false;
        this.$refs.snackbar.showSnackbar("Tous_obligatoires");
        this.isCompleted = false;

        return;
      }
      
      this.$emit("changeStep", 6, true);
  
      this.isLoading = false; },
    goToPrevStep() {
      if(this.visibleTabsLength<=1){
        this.$emit("changePrev",true);
      }else {
        this.$emit("changePrev",false);
      }
      this.$emit("changeStep", this.activeState - 1);
    },
  },
};
</script>
<style scoped>
.custom-placeholder::placeholder {
  font-size: 13px;
}
.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.input-style {
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eaebec;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
}

.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.custom-input {
  box-shadow: none;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>
