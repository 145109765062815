<template>
  <div>
    <!-- Tabs -->
    <div class="tabs-container">
      <v-tabs
        v-if="visibleTabs.length > 1"
        v-model="step"
        bg-color="deep-purple-darken-4"
        center-active
        class="justify-center tab"
      >
        <v-tab v-for="(tab, index) in visibleTabs" :key="index" class="mb-3">
          {{ tab }}
        </v-tab>
      </v-tabs>
    </div>

    <!-- Dynamic Content -->
    <div v-for="(tab, index) in visibleTabs" :key="index" class="margincard">
      <div
        v-if="step === index"
        class="d-flex justify-content-center align-items-center flex-column"
      >
        <div class="col-lg-8 col-sm-12 col-md-10 borderPointier mb-2">
          <div class="background-white">
            <div class="title text-center">
              <div class="position">{{ $t("INSTALLATEUR") }} - {{ tab }}</div>
            </div>
          </div>

          <!-- Form -->
          <form class="my-4 w-100">
            <div class="center">
              <b-form-group
                :label="$t('QUESTION_SOUE-TRAITANT')"
                class="form-input custom-placeholder legend mt-3"
              >
                <div
                  class="d-flex form-input custom-placeholder custom-input mb-3"
                >
                  <b-form-radio
                    v-model="stepsData[index].selected"
                    class="mb-3 mx-2"
                    name="some-radios"
                    :value="'A'"
                    @change="watchStepSelection(index, 'A')"
                  >
                    {{ $t("YES") }}
                  </b-form-radio>
                  <b-form-radio
                    v-model="stepsData[index].selected"
                    class="mb-3 mx-2"
                    name="some-radios"
                    :value="'B'"
                    @change="watchStepSelection(index, 'B')"
                  >
                    {{ $t("NO") }}
                  </b-form-radio>
                </div>
              </b-form-group>
              <div v-if="stepsData[index].selected === 'B'">
                <div class="d-flex align-items-center justify-content-start">
                  <div>{{ $t("SOUS-TRAITANT") }}</div>
               
                </div>
                <div class="d-flex align-items-center justify-content-start">
                <b-form-group class="my-4 flex-grow-1">
                  <multiselect
                    v-model="stepsData[index].selectedSousTraitantData"
                    :searchable="false"
                    :close-on-select="true"
                    :multiple="false"
                    :options="getAllsousTraitant"
                    label="name"
                    track-by="id"
                    :placeholder="$t('SELECTED_SOUE-TRAITANT')"
                    class="form-input custom-placeholder custom-input"
                    @input="handleSelectionChange(index)"
                  />
                </b-form-group>
                <button  style="flex-shrink: 0;"
                    id="add-wit-popover"
                     class="mb-1 mx-3 py-0 newstore rounded"
                    @click.prevent="handleStore()"
                  >
                    +
                  </button>
                </div>
                <b-popover
                  target="add-wit-popover"
                  placement="top"
                  triggers="hover focus"
                >
                  {{ $t("Vous pouvez ajouter un sous-traitant") }}
                </b-popover>

                <storeModalVue @updateList="setup(true)"></storeModalVue>

                <b-form-group :label="$t('NAME')">
                  <b-form-input
                    v-model="stepsData[index].selectedSousTraitantData.name"
                    :placeholder="$t('NAME')"
                    class="form-input custom-placeholder custom-input"
                    oninput="this.value = this.value.toUpperCase()"
                  ></b-form-input>
                </b-form-group>

                <b-form-group :label="$t('SIRET')">
                  <b-form-input
                    v-model="
                      stepsData[index].selectedSousTraitantData.num_siret
                    "
                    :placeholder="$t('SIRET')"
                    class="form-input custom-placeholder custom-input"
                    oninput="this.value = this.value.toUpperCase()"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
      
              <div class="my-3">{{ $t("RGE") }}</div>
         
         
            <div class="d-flex align-items-center justify-content-start">
  <b-form-group label-for="num_rge" class="mb-5 flex-grow-1">
    <multiselect
      v-model="stepsData[index].selectedCertificat"
      :searchable="true"
      :close-on-select="true"
      :multiple="false"
      :options="getAllCertificatRges"
      label="num"
      track-by="id"
      :placeholder="$t('Sélectionne RGE')"
      class="form-input custom-placeholder custom-input"
      @input="handleSelectionChangeRGE(index)"
    />
  </b-form-group>

  <button
    id="add-with-popover"
    class="mb-5 mx-3 py-0 newstore rounded"
    @click.prevent="openModal()"
    style="flex-shrink: 0;" 
  >
    +
  </button>
</div>

            <!-- <div class="d-flex align-items-center justify-content-start">
            <b-form-group label-for="num_rge" class="mb-5 index flex-2">
              <multiselect
                v-model="stepsData[index].selectedCertificat"
                :searchable="true"
                :close-on-select="true"
                :multiple="false"
                :options="getAllCertificatRges"
                label="num"
                track-by="id"
                :placeholder="$t('Sélectionne RGE')"
                class="form-input custom-placeholder custom-input"
                @input="handleSelectionChangeRGE(index)"
              />
            </b-form-group>
            <button
                id="add-with-popover"
                class="mb-1 mx-3 py-0 newstore rounded"
                @click.prevent="openModal()"
              >
                +
              </button>
            </div> -->
            <certificatRGEmodal
              @updateList="
                setup(
                  true,
                  stepsData[index].selected,
                  stepsData[index].selectedSousTraitantData
                )
              "
            ></certificatRGEmodal>
            <b-popover
              target="add-with-popover"
              placement="top"
              triggers="hover focus"
            >
              {{
                $t(
                  "Vous pouvez ajouter un certificat à votre entreprise/sous-traitant"
                )
              }}
            </b-popover>
          </form>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-4">
      <b-button
        v-if="visibleTabs.length <= 1"
        @click="goToPrevStep"
        class="button-pre-style m-3"
      >
        {{ $t("PREVIOUS") }}
      </b-button>
      <b-button @click="goToStep(step)" class="button-suivant-style m-3">
        {{ buttonText }}
        <div class="loading ml-2" v-if="isLoading">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div>
    <div v-if="visibleTabs.length > 1" class="d-flex justify-content-end mt-4">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
        {{ $t("NEXT") }}

        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div>
    <Snackbar ref="snackbar" class="mt-5" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import storeModalVue from "@/components/Ajoutmodel/sousTraitantmodal.vue";
import Snackbar from "@/components/ui/errorSnackbar.vue";
import certificatRGEmodal from "@/components/Ajoutmodel/certificatRGEmodal.vue";
export default {
  components: {
    storeModalVue,
    Snackbar,
    certificatRGEmodal,
  },
  props: {
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      update_EntiteExterne: null,
      step: 0,
      errorMessage: "",
      isLoading: false,
      stepsData: [],
      tabs: [
        "Isolation de combles ou de toiture",
        "Isolation des murs",
        "Isolation d’un plancher",
        "Fenêtre ou porte-fenêtre complète avec vitrage isolant",
      ],
      stepActiv: [
        "Isolation des murs",
        "Pompe à chaleur de type air/eau",
        "Isolation de combles ou de toiture",
      ],
    };
  },
  watch: {
    step(newVal) {
      this.fetchDataForStep(newVal);
    },
    selectedSousTraitantData(newVal) {
      if (this.selected === "B") {
        this.fetchData(
          this.selected,
          this.stepsData[newVal].selectedSousTraitantData.id,
          this.selected
        );
      }
      if (this.selected === "A") {
        this.fetchData(this.selected);
      }
    },
  },
  computed: {
    ...mapGetters([
      "getOnlineUser",
      "getdevisMonoFiche",
      "getAllsousTraitant",
      "getAllCertificatRges",
    ]),
    buttonText() {
      return this.visibleTabs.length <= 1
        ? this.$t("NEXT")
        : this.$t("CONFIRMER");
    },
    visibleTabs() {
      const groupeLigneDocument =
        this.getdevisMonoFiche?.data?.groupeLigneDocument || [];

      const typeGroups = groupeLigneDocument.map((item) => item.type_group);

      return typeGroups;
    },
  },

  created() {
    this.allsousTraitant({
      search: "",
      page: this.page,
      per_page: 100000,
      entreprise: this.getdevisMonoFiche.data.devis.entreprise,
    });
    this.initializeStepsData();
    this.fetchDataForStep(this.step);
  },
  methods: {
    ...mapActions([
      "allsousTraitant",
      "setDevisMonoFiche",
      "updateGroupe",
      "allCertificatRge",
      "edit_sousTraitant",
      "store_sousTraitant",
    ]),
    openModal() {
      this.$root.$emit("bv::show::modal", "certificatRGEmodal");
    },
    handleStore() {
      this.$root.$emit("bv::show::modal", "sousTraitantModal");
    },
    closeModal() {
      this.showModal = false;
      this.setup(true);
    },
    setup(refresh = false, selec, sous_traitantD) {
      if (refresh) this.page = 1;
      this.allsousTraitant({
        search: "",
        page: this.page,
        per_page: 100000,
        entreprise: this.getdevisMonoFiche.data.devis.entreprise,
      });
      this.fetchData(selec, sous_traitantD.id);
    },

    fetchDataForStep(step) {
      const currentStepData = this.stepsData[step];

      if (!currentStepData) {
        return;
      }

      const { selected, selectedSousTraitantData } = currentStepData;

      if (selected === "A") {
        this.fetchData("A");
      } else if (selected === "B" && selectedSousTraitantData?.id) {
        this.fetchData("B", selectedSousTraitantData.id);
      }
    },

    async fetchData(selected, sousTraitantId = null) {
      const baseParams = {
        page: 1,

        search: "",
      };

      if (selected === "A") {
        await this.allCertificatRge({
          ...baseParams,
          per_page: 10000000,
          entreprise_id: this.getdevisMonoFiche.data.devis.entreprise.id,
          sous_traitant_id: "",
        });
      } else if (selected === "B") {
        if (sousTraitantId === null) {
          await this.allCertificatRge({
            page: 1,
            per_page: 0,
            sous_traitant_id: sousTraitantId || "",
            entreprise_id: "",
          });
        } else {
          await this.allCertificatRge({
            ...baseParams,
            per_page: 10000000,
            sous_traitant_id: sousTraitantId || "",
            entreprise_id: "",
          });
        }
      }
    },
    handleSelectionChange(index) {
      const selected = this.stepsData[index].selectedSousTraitantData;
      this.fetchData("B", selected.id);
      if (!selected || (selected && selected.id === null)) {
        this.fetchData("B", selected.id);
        this.stepsData[index].selectedSousTraitantData = {
          id: null,
          name: "",
          num_siret: "",
        };
      }
      this.stepsData[index].selectedCertificat = {
        id: null,
        num: null,
      };
    },
    handleSelectionChangeRGE(index) {
      const selectedRGE = this.stepsData[index].selectedCertificat;
      if (!selectedRGE || (selectedRGE && selectedRGE.id === null)) {
        this.stepsData[index].selectedCertificat = {
          id: null,
          num: "",
        };
      }
    },
    watchStepSelection(index, selected) {
      if (selected === "A") {
        this.updateStepSousTraitant(index, null);
        this.fetchData(selected);
        this.stepsData[index].selectedCertificat = {
          id: null,
          num: null,
        };
      }
      if (selected === "B") {
        this.fetchData(selected);
        this.stepsData[index].selectedCertificat = {
          id: null,
          num: null,
        };
      }
    },
    updateStepSousTraitant(index, value) {
      const stepData = this.stepsData[index];

      if (value && Object.keys(value).length > 0) {
        stepData.selectedSousTraitantData = { ...value };
        this.fetchData("B", this.stepsData[index].selectedSousTraitantData.id);
        this.stepsData[index].selectedCertificat = {
          id: null,
          num: null,
        };
        this.getdevisMonoFiche.data.groupeLigneDocument[index].sous_traitant = {
          ...value,
        };
        this.getdevisMonoFiche.data.groupeLigneDocument[
          index
        ].sous_traitant_id = value.id;
      } else {
        stepData.selectedSousTraitantData = {
          id: null,
          name: "",
          num_siret: "",
        };
        this.getdevisMonoFiche.data.groupeLigneDocument[index].sous_traitant = {
          id: null,
          name: "",
          num_siret: "",
        };
        this.getdevisMonoFiche.data.groupeLigneDocument[
          index
        ].sous_traitant_id = null;
        this.fetchData("A");
        this.stepsData[index].selectedCertificat = {
          id: null,
          num: null,
        };
      }
    },

    initializeSousTraitant(index) {
      const sousTraitant =
        this.getdevisMonoFiche?.data?.groupeLigneDocument[index]
          ?.sous_traitant || {};
      if (
        this.getdevisMonoFiche?.data?.groupeLigneDocument[index]?.sous_traitant
      ) {
        this.fetchData("B", sousTraitant.id);
      } else {
        this.fetchData("A");
      }

      return {
        id: sousTraitant.id || null,
        name: sousTraitant.name || "",
        num_siret: sousTraitant.num_siret || "",
      };
    },
    initializeRGE(index) {
      const rge =
        this.getdevisMonoFiche?.data?.groupeLigneDocument[index]
          ?.selected_certificat_RGE || {};
      return {
        id: rge.id || null,
        num: rge.num || "",
      };
    },
    initializeStepsData() {
      this.stepsData = this.getdevisMonoFiche?.data?.groupeLigneDocument.map(
        (item, index) => {
          const sousTraitantData = this.initializeSousTraitant(index);
          const selected = sousTraitantData.id ? "B" : "A";
          const certificatRge = this.initializeRGE(index);

          return {
            index,
            selected,

            selectedSousTraitantData: sousTraitantData,
            selectedCertificat: certificatRge,
          };
        }
      );
    },
    async edit(index) {
      const stepData = this.stepsData[index];
      if (stepData.selected === "A") {
        const result = {
          id: this.getdevisMonoFiche.data.groupeLigneDocument[index].id,
          sous_traitant_id: null,
          certificat_rge_id: this.stepsData[index].selectedCertificat.id,
        };

        this.getdevisMonoFiche.data.groupeLigneDocument[
          index
        ].certificat_rge_id = this.stepsData[index].selectedCertificat.id;
        this.getdevisMonoFiche.data.groupeLigneDocument[
          index
        ].selected_certificat_RGE = {
          id: this.stepsData[index].selectedCertificat.id,
          num: this.stepsData[index].selectedCertificat.num,
        };
        this.setDevisMonoFiche(this.getdevisMonoFiche);
        await this.updateGroupe(result);

        this.getdevisMonoFiche.data.groupeLigneDocument[
          index
        ].selected_certificat_RGE = {
          id: this.stepsData[index].selectedCertificat.id,
          num: this.stepsData[index].selectedCertificat.num,
        };
        this.getdevisMonoFiche.data.groupeLigneDocument[
          index
        ].sous_traitant_id = null;
        this.getdevisMonoFiche.data.groupeLigneDocument[index].sous_traitant =
          null;
        this.setDevisMonoFiche(this.getdevisMonoFiche);
      } else if (
        stepData.selected === "B" &&
        stepData.selectedSousTraitantData.id !== null
      ) {
        await this.edit_sousTraitant(stepData.selectedSousTraitantData);
        this.setDevisMonoFiche(this.getdevisMonoFiche);

        const result = {
          id: this.getdevisMonoFiche.data.groupeLigneDocument[index].id,
          sous_traitant_id: stepData.selectedSousTraitantData.id,
          certificat_rge_id: this.stepsData[index].selectedCertificat.id,
        };
        this.getdevisMonoFiche.data.groupeLigneDocument[
          index
        ].selected_certificat_RGE_id =
          this.stepsData[index].selectedCertificat.id;

        this.getdevisMonoFiche.data.groupeLigneDocument[
          index
        ].certificat_rge_id = this.stepsData[index].selectedCertificat.id;
        this.getdevisMonoFiche.data.groupeLigneDocument[
          index
        ].selected_certificat_RGE = {
          id: this.stepsData[index].selectedCertificat.id,
          num: this.stepsData[index].selectedCertificat.num,
        };
        await this.updateGroupe(result);
        this.getdevisMonoFiche.data.groupeLigneDocument[
          index
        ].certificat_rge_id = this.stepsData[index].selectedCertificat.id;
        this.getdevisMonoFiche.data.groupeLigneDocument[
          index
        ].selected_certificat_RGE = {
          id: this.stepsData[index].selectedCertificat.id,
          num: this.stepsData[index].selectedCertificat.num,
        };
        this.setDevisMonoFiche(this.getdevisMonoFiche);
      } else {
        await this.createSousTraitant(index);
        this.getdevisMonoFiche.data.groupeLigneDocument[
          index
        ].selected_certificat_RGE = {
          id: this.stepsData[index].selectedCertificat.id,
          num: this.stepsData[index].selectedCertificat.num,
        };
        this.setDevisMonoFiche(this.getdevisMonoFiche);
      }
    },
    async createSousTraitant(index) {
      const res = await this.store_sousTraitant(
        this.stepsData[index].selectedSousTraitantData
      );

      this.getdevisMonoFiche.data.groupeLigneDocument[index].sous_traitant_id =
        res.data.id;
      this.getdevisMonoFiche.data.groupeLigneDocument[index].sous_traitant = {
        id: res.data.id,
        name: this.stepsData[index].selectedSousTraitantData.name,
        num_siret: this.stepsData[index].selectedSousTraitantData.num_siret,
      };
      const result = {
        id: this.getdevisMonoFiche.data.groupeLigneDocument[index].id,
        sous_traitant_id: res.data.id,
        certificat_rge_id: this.stepsData[index].selectedCertificat.id,
      };
      await this.updateGroupe(result);
      this.getdevisMonoFiche.data.groupeLigneDocument[index].certificat_rge_id =
        this.stepsData[index].selectedCertificat.id;
      this.getdevisMonoFiche.data.groupeLigneDocument[
        index
      ].selected_certificat_RGE = {
        id: this.stepsData[index].selectedCertificat.id,
        num: this.stepsData[index].selectedCertificat.num,
      };
      this.setDevisMonoFiche(this.getdevisMonoFiche);
    },

    goToStep(index) {
      this.isLoading = true;
      setTimeout(() => {
        this.edit(index);
        this.isLoading = false;
        if (this.visibleTabs.length <= 1) {
          this.goToNextStep();
        }
      }, 500);
    },
    async goToNextStep() {
      this.stepsData.forEach((stepData, index) => {
        if (this.getdevisMonoFiche.data.groupeLigneDocument[index]) {
          this.getdevisMonoFiche.data.groupeLigneDocument[index].sous_traitant =
            stepData.selectedSousTraitantData || null;
        }
      });
      const groupeLigneDocuments =
        this.getdevisMonoFiche.data.groupeLigneDocument || [];
      for (const ligne of groupeLigneDocuments) {
        if (
          ligne.selected_certificat_RGE === null &&
          ligne.sous_traitant === null
        ) {
          this.errorMessage =
            "Chaque étape doit être certifiée par un certificat RGE";
          this.$refs.snackbar.showSnackbar(this.errorMessage);
          return;
        }
        if (ligne.sous_traitant.id !== null) {
          this.errorMessage = "";
        }
      }
      for (const ligne of groupeLigneDocuments) {
        this.errorMessage = "";
        if (
          ligne.selected_certificat_RGE?.id === null ||
          ligne.selected_certificat_RGE === null
        ) {
          this.errorMessage = "Il faut confirmer toutes les étapes.";
          this.$refs.snackbar.showSnackbar(this.errorMessage);
          return;
        }
   
      }



      this.$emit("changeStep", 4, true);
    },
    goToPrevStep() {
      this.$emit("changeStepPrev", 2);
    },
    async saveStepData(index) {
      this.stepsData[index];
    },
  },
};
</script>

<style scoped>
.tab {
  display: ruby;
}

.v-slide-group__wrapper {
  display: ruby;
}

.tabs-container {
  display: flex;
  justify-content: center; /* Centre le composant horizontalement */
}
.margincard {
  margin: inherit;
  width: -webkit-fill-available;
}
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
  margin-bottom: 25px;
  margin: inherit;
  height: 100%;
  width: 90%;
}
.center .legend {
  font-size: 16px;
}
.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.custom-placeholder::placeholder {
  font-size: 13px;
}

.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}
.custom-input {
  box-shadow: none;
}
.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.index {
  z-index: 20 !important;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>
